import {TenantService} from './tenant.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LatestLesson} from '../models/latest-lesson.model';
import {readCollectionWithIds} from '../common/firestore-utils';
import { Firestore, QueryConstraint, collection, collectionData, limit, orderBy, query, startAfter, where } from '@angular/fire/firestore';
import {Timestamp} from '@firebase/firestore';
import { OrderByDirection } from '@firebase/firestore';



@Injectable({
  providedIn: 'root'
})
export class LatestLessonsDbService {

  constructor(private tenant: TenantService,
              private firestore: Firestore) {

  }

  loadLatestLessonsPage(startAfterTimestamp: Timestamp, sortOrder:OrderByDirection, pageSize = 10): Observable<LatestLesson[]> {

    const latestLessonsPath = `schools/${this.tenant.id}/latestLessonsView`;

    const constraints: QueryConstraint[] = [
      where("courseStatus", '==', 'published' ),
      where("hiddenCourseOn", '==', false ),
      orderBy('lastUpdated', sortOrder),
      limit(pageSize)
    ]

    if(startAfterTimestamp) {
      constraints.push(startAfter(startAfterTimestamp))
    }

    const query$ = query(collection(this.firestore, latestLessonsPath), ...constraints)

    const queryData = collectionData(query$, {idField: 'id'});

    return readCollectionWithIds(queryData);

  }
}
